/* models/vtk/index.js */ function _(require, module, exports, __esModule, __esExport) {
    __esModule();
    var vtkjs_1 = require("ac55912dc1") /* ./vtkjs */;
    __esExport("VTKJSPlot", vtkjs_1.VTKJSPlot);
    var vtkvolume_1 = require("4797a2858f") /* ./vtkvolume */;
    __esExport("VTKVolumePlot", vtkvolume_1.VTKVolumePlot);
    var vtkaxes_1 = require("0379dcf1cd") /* ./vtkaxes */;
    __esExport("VTKAxes", vtkaxes_1.VTKAxes);
    var vtksynchronized_1 = require("a4e5946204") /* ./vtksynchronized */;
    __esExport("VTKSynchronizedPlot", vtksynchronized_1.VTKSynchronizedPlot);
}
