/* models/button_icon.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const icon_1 = require("17193475a8") /* ./icon */;
    class ButtonIconView extends icon_1.ClickableIconView {
        *controls() { }
        update_cursor() {
            this.icon_view.el.style.cursor = this.model.disabled ? "default" : "pointer";
        }
        click() {
            if (this.model.disabled) {
                return;
            }
            super.click();
            const updateState = (value, disabled) => {
                this.model.value = value;
                this.model.disabled = disabled;
            };
            updateState(true, true);
            new Promise(resolve => setTimeout(resolve, this.model.toggle_duration))
                .then(() => {
                updateState(false, false);
            });
        }
    }
    exports.ButtonIconView = ButtonIconView;
    ButtonIconView.__name__ = "ButtonIconView";
    class ButtonIcon extends icon_1.ClickableIcon {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.ButtonIcon = ButtonIcon;
    _a = ButtonIcon;
    ButtonIcon.__name__ = "ButtonIcon";
    ButtonIcon.__module__ = "panel.models.icon";
    (() => {
        _a.prototype.default_view = ButtonIconView;
        _a.define(({ Int }) => ({
            toggle_duration: [Int, 75],
        }));
    })();
}
