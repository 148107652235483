/* models/textarea_input.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const textarea_input_1 = require("@bokehjs/models/widgets/textarea_input");
    class TextAreaInputView extends textarea_input_1.TextAreaInputView {
        connect_signals() {
            super.connect_signals();
            const { value, max_rows } = this.model.properties;
            this.on_change([max_rows, value], () => this.update_rows());
        }
        update_rows() {
            if (!this.model.auto_grow) {
                return;
            }
            // Use this.el directly to access the textarea element
            const textarea = this.input_el;
            const textLines = textarea.value.split("\n");
            const numRows = Math.max(textLines.length, this.model.rows, 1);
            textarea.rows = Math.min(numRows, this.model.max_rows || Infinity);
        }
        render() {
            super.render();
            this.update_rows();
            this.el.addEventListener("input", () => {
                this.update_rows();
            });
        }
    }
    exports.TextAreaInputView = TextAreaInputView;
    TextAreaInputView.__name__ = "TextAreaInputView";
    class TextAreaInput extends textarea_input_1.TextAreaInput {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.TextAreaInput = TextAreaInput;
    _a = TextAreaInput;
    TextAreaInput.__name__ = "TextAreaInput";
    TextAreaInput.__module__ = "panel.models.widgets";
    (() => {
        _a.prototype.default_view = TextAreaInputView;
        _a.define(({ Bool, Int, Nullable }) => ({
            auto_grow: [Bool, false],
            max_rows: [Nullable(Int), null],
        }));
    })();
}
