/* models/vizzu.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const dom_1 = require("@bokehjs/core/dom");
    const types_1 = require("@bokehjs/core/util/types");
    const column_data_source_1 = require("@bokehjs/models/sources/column_data_source");
    const bokeh_events_1 = require("@bokehjs/core/bokeh_events");
    const debounce_1 = require("99a25e6992") /* debounce */;
    const layout_1 = require("73d6aee8f5") /* ./layout */;
    class VizzuEvent extends bokeh_events_1.ModelEvent {
        constructor(data) {
            super();
            this.event_name = "vizzu_event";
            this.publish = true;
            this.data = data;
        }
        get event_values() {
            return { model: this.origin, data: this.data };
        }
    }
    exports.VizzuEvent = VizzuEvent;
    VizzuEvent.__name__ = "VizzuEvent";
    const VECTORIZED_PROPERTIES = ["x", "y", "color", "label", "lightness", "size", "splittedBy", "dividedBy"];
    class VizzuChartView extends layout_1.HTMLBoxView {
        constructor() {
            super(...arguments);
            this.update = [];
            this._animating = false;
        }
        connect_signals() {
            super.connect_signals();
            const update = (0, debounce_1.debounce)(() => {
                if (!this.valid_config) {
                    console.warn("Vizzu config not valid given current data.");
                    return;
                }
                else if ((this.update.length === 0) || this._animating) {
                    return;
                }
                else {
                    let change = {};
                    for (const prop of this.update) {
                        if (prop === "config") {
                            change = { ...change, config: this.config() };
                        }
                        else if (prop === "data") {
                            change = { ...change, data: this.data() };
                        }
                        else {
                            change = { ...change, style: this.model.style };
                        }
                    }
                    this._animating = true;
                    this.vizzu_view.animate(change, `${this.model.duration}ms`).then(() => {
                        this._animating = false;
                        if (this.update.length > 0) {
                            update();
                        }
                    });
                    this.update = [];
                }
            }, 20);
            const update_prop = (prop) => {
                if (!this.update.includes(prop)) {
                    this.update.push(prop);
                }
                update();
            };
            const { config, tooltip, style } = this.model.properties;
            this.on_change(config, () => update_prop("config"));
            this.on_change(this.model.source.properties.data, () => update_prop("data"));
            this.connect(this.model.source.streaming, () => update_prop("data"));
            this.connect(this.model.source.patching, () => update_prop("data"));
            this.on_change(tooltip, () => {
                this.vizzu_view.feature("tooltip", this.model.tooltip);
            });
            this.on_change(style, () => update_prop("style"));
        }
        get valid_config() {
            const columns = this.model.source.columns();
            if ("channels" in this.model.config) {
                for (const col of Object.values(this.model.config.channels)) {
                    if ((0, types_1.isArray)(col)) {
                        for (const c of col) {
                            if (col != null && !columns.includes(c)) {
                                return false;
                            }
                        }
                    }
                    else if ((0, types_1.isObject)(col)) {
                        for (const prop of Object.keys(col)) {
                            for (const c of col[prop]) {
                                if (col != null && !columns.includes(c)) {
                                    return false;
                                }
                            }
                        }
                    }
                    else if (col != null && !columns.includes(col)) {
                        return false;
                    }
                }
            }
            else {
                for (const prop of VECTORIZED_PROPERTIES) {
                    if (prop in this.model.config && !columns.includes(this.model.config[prop])) {
                        return false;
                    }
                }
            }
            return true;
        }
        config() {
            let config = { ...this.model.config };
            if ("channels" in config) {
                config.channels = { ...config.channels };
            }
            if (config.preset != undefined) {
                config = window.Vizzu.presets[config.preset](config);
            }
            return config;
        }
        data() {
            const series = [];
            for (const column of this.model.columns) {
                let array = [...this.model.source.get_array(column.name)];
                if (column.type === "datetime" || column.type == "date") {
                    column.type = "dimension";
                }
                if (column.type === "dimension") {
                    array = array.map(String);
                }
                series.push({ ...column, values: array });
            }
            return { series };
        }
        render() {
            super.render();
            this.container = (0, dom_1.div)({ style: "display: contents;" });
            this.shadow_el.append(this.container);
            const state = { config: this.config(), data: this.data(), style: this.model.style };
            this.vizzu_view = new window.Vizzu(this.container, state);
            this._animating = true;
            this.vizzu_view.initializing.then((chart) => {
                chart.on("click", (event) => {
                    this.model.trigger_event(new VizzuEvent({ ...event.target, ...event.detail }));
                });
                chart.feature("tooltip", this.model.tooltip);
                this._animating = false;
            });
        }
        remove() {
            if (this.vizzu_view) {
                this.vizzu_view.detach();
            }
            super.remove();
        }
    }
    exports.VizzuChartView = VizzuChartView;
    VizzuChartView.__name__ = "VizzuChartView";
    class VizzuChart extends layout_1.HTMLBox {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.VizzuChart = VizzuChart;
    _a = VizzuChart;
    VizzuChart.__name__ = "VizzuChart";
    VizzuChart.__module__ = "panel.models.vizzu";
    (() => {
        _a.prototype.default_view = VizzuChartView;
        _a.define(({ Any, List, Bool, Float, Ref }) => ({
            animation: [Any, {}],
            config: [Any, {}],
            columns: [List(Any), []],
            source: [Ref(column_data_source_1.ColumnDataSource)],
            duration: [Float, 500],
            style: [Any, {}],
            tooltip: [Bool, false],
        }));
    })();
}
