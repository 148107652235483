/* models/trend.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const layout_1 = require("73d6aee8f5") /* ./layout */;
    const build_views_1 = require("@bokehjs/core/build_views");
    const plots_1 = require("@bokehjs/models/plots");
    const glyphs_1 = require("@bokehjs/models/glyphs");
    const dom_1 = require("@bokehjs/core/dom");
    const column_data_source_1 = require("@bokehjs/models/sources/column_data_source");
    const formatters_1 = require("@bokehjs/models/formatters");
    const red = "#d9534f";
    const green = "#5cb85c";
    const blue = "#428bca";
    class TrendIndicatorView extends layout_1.HTMLBoxView {
        initialize() {
            super.initialize();
            this.containerDiv = (0, dom_1.div)({ style: "height:100%; width:100%;" });
            this.titleDiv = (0, dom_1.div)({ style: "font-size: 1em; word-wrap: break-word;" });
            this.valueDiv = (0, dom_1.div)({ style: "font-size: 2em" });
            this.value2Div = (0, dom_1.div)({ style: "font-size: 1em; opacity: 0.5; display: inline" });
            this.changeDiv = (0, dom_1.div)({ style: "font-size: 1em; opacity: 0.5; display: inline" });
            this.textDiv = (0, dom_1.div)({}, this.titleDiv, this.valueDiv, (0, dom_1.div)({}, this.changeDiv, this.value2Div));
            this.updateTitle();
            this.updateValue();
            this.updateValue2();
            this.updateValueChange();
            this.updateTextFontSize();
            this.plotDiv = (0, dom_1.div)({});
            this.containerDiv = (0, dom_1.div)({ style: "height:100%; width:100%" }, this.textDiv, this.plotDiv);
            this.updateLayout();
        }
        connect_signals() {
            super.connect_signals();
            const { pos_color, neg_color, plot_color, plot_type, width, height, sizing_mode, title, value, value_change, layout, } = this.model.properties;
            this.on_change([pos_color, neg_color], () => this.updateValueChange());
            this.on_change([plot_color, plot_type, width, height, sizing_mode], () => this.render());
            this.on_change(title, () => this.updateTitle(true));
            this.on_change(value, () => this.updateValue(true));
            this.on_change(value_change, () => this.updateValue2(true));
            this.on_change(layout, () => this.updateLayout());
        }
        async render() {
            super.render();
            this.shadow_el.appendChild(this.containerDiv);
            await this.setPlot();
        }
        async setPlot() {
            this.plot = new plots_1.Plot({
                background_fill_color: null,
                border_fill_color: null,
                outline_line_color: null,
                min_border: 0,
                sizing_mode: "stretch_both",
                toolbar_location: null,
            });
            const source = this.model.source;
            if (this.model.plot_type === "line") {
                const line = new glyphs_1.Line({
                    x: { field: this.model.plot_x },
                    y: { field: this.model.plot_y },
                    line_width: 4,
                    line_color: this.model.plot_color,
                });
                this.plot.add_glyph(line, source);
            }
            else if (this.model.plot_type === "step") {
                const step = new glyphs_1.Step({
                    x: { field: this.model.plot_x },
                    y: { field: this.model.plot_y },
                    line_width: 3,
                    line_color: this.model.plot_color,
                });
                this.plot.add_glyph(step, source);
            }
            else if (this.model.plot_type === "area") {
                const varea = new glyphs_1.VArea({
                    x: { field: this.model.plot_x },
                    y1: { field: this.model.plot_y },
                    y2: 0,
                    fill_color: this.model.plot_color,
                    fill_alpha: 0.5,
                });
                this.plot.add_glyph(varea, source);
                const line = new glyphs_1.Line({
                    x: { field: this.model.plot_x },
                    y: { field: this.model.plot_y },
                    line_width: 3,
                    line_color: this.model.plot_color,
                });
                this.plot.add_glyph(line, source);
            }
            else {
                const vbar = new glyphs_1.VBar({
                    x: { field: this.model.plot_x },
                    top: { field: this.model.plot_y },
                    width: 0.9,
                    line_color: null,
                    fill_color: this.model.plot_color,
                });
                this.plot.add_glyph(vbar, source);
            }
            const view = await (0, build_views_1.build_view)(this.plot);
            this.plotDiv.innerHTML = "";
            view.render_to(this.plotDiv);
        }
        after_layout() {
            super.after_layout();
            this.updateTextFontSize();
        }
        updateTextFontSize() {
            this.updateTextFontSizeColumn();
        }
        updateTextFontSizeColumn() {
            let elWidth = this.containerDiv.clientWidth;
            let elHeight = this.containerDiv.clientHeight;
            if (this.model.layout === "column") {
                elHeight = Math.round(elHeight / 2);
            }
            else {
                elWidth = Math.round(elWidth / 2);
            }
            const widthTitle = this.model.title.length;
            const widthValue = 2 * this._value_format.length;
            const widthValue2 = this._value_change_format.length + 1;
            const widthConstraint1 = elWidth / widthTitle * 2.0;
            const widthConstraint2 = elWidth / widthValue * 1.8;
            const widthConstraint3 = elWidth / widthValue2 * 2.0;
            const heightConstraint = elHeight / 6;
            const fontSize = Math.min(widthConstraint1, widthConstraint2, widthConstraint3, heightConstraint);
            this.textDiv.style.fontSize = `${Math.trunc(fontSize)}px`;
            this.textDiv.style.lineHeight = "1.3";
        }
        updateTitle(update_fontsize = false) {
            this.titleDiv.innerText = this.model.title;
            if (update_fontsize) {
                this.updateTextFontSize();
            }
        }
        updateValue(update_fontsize = false) {
            this._value_format = this.model.formatter.doFormat([this.model.value], { loc: 0 })[0];
            this.valueDiv.innerText = this._value_format;
            if (update_fontsize) {
                this.updateTextFontSize();
            }
        }
        updateValue2(update_fontsize = false) {
            this._value_change_format = this.model.change_formatter.doFormat([this.model.value_change], { loc: 0 })[0];
            this.value2Div.innerText = this._value_change_format;
            this.updateValueChange();
            if (update_fontsize) {
                this.updateTextFontSize();
            }
        }
        updateValueChange() {
            if (this.model.value_change > 0) {
                this.changeDiv.innerHTML = "&#9650;";
                this.changeDiv.style.color = this.model.pos_color;
            }
            else if (this.model.value_change < 0) {
                this.changeDiv.innerHTML = "&#9660;";
                this.changeDiv.style.color = this.model.neg_color;
            }
            else {
                this.changeDiv.innerHTML = "&nbsp;";
                this.changeDiv.style.color = "inherit";
            }
        }
        updateLayout() {
            if (this.model.layout === "column") {
                this.containerDiv.style.display = "block";
                this.textDiv.style.height = "50%";
                this.textDiv.style.width = "100%";
                this.plotDiv.style.height = "50%";
                this.plotDiv.style.width = "100%";
            }
            else {
                this.containerDiv.style.display = "flex";
                this.textDiv.style.height = "100%";
                this.textDiv.style.width = "";
                this.plotDiv.style.height = "100%";
                this.plotDiv.style.width = "";
                this.textDiv.style.flex = "1";
                this.plotDiv.style.flex = "1";
            }
            if (this._has_finished) {
                this.invalidate_layout();
            }
        }
    }
    exports.TrendIndicatorView = TrendIndicatorView;
    TrendIndicatorView.__name__ = "TrendIndicatorView";
    class TrendIndicator extends layout_1.HTMLBox {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.TrendIndicator = TrendIndicator;
    _a = TrendIndicator;
    TrendIndicator.__name__ = "TrendIndicator";
    TrendIndicator.__module__ = "panel.models.trend";
    (() => {
        _a.prototype.default_view = TrendIndicatorView;
        _a.define(({ Float, Str, Ref }) => ({
            description: [Str, ""],
            formatter: [Ref(formatters_1.TickFormatter), () => new formatters_1.BasicTickFormatter()],
            change_formatter: [Ref(formatters_1.TickFormatter), () => new formatters_1.NumeralTickFormatter()],
            layout: [Str, "column"],
            source: [Ref(column_data_source_1.ColumnDataSource)],
            plot_x: [Str, "x"],
            plot_y: [Str, "y"],
            plot_color: [Str, blue],
            plot_type: [Str, "bar"],
            pos_color: [Str, green],
            neg_color: [Str, red],
            title: [Str, ""],
            value: [Float, 0],
            value_change: [Float, 0],
        }));
    })();
}
