/* models/customselect.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const select_1 = require("@bokehjs/models/widgets/select");
    class CustomSelectView extends select_1.SelectView {
        connect_signals() {
            super.connect_signals();
            const { disabled_options } = this.model.properties;
            this.on_change(disabled_options, () => this._update_disabled_options());
        }
        options_el() {
            const opts = super.options_el();
            const { disabled_options } = this.model;
            opts.forEach((element) => {
                // XXX: what about HTMLOptGroupElement?
                if (element instanceof HTMLOptionElement && disabled_options.includes(element.value)) {
                    element.setAttribute("disabled", "true");
                }
            });
            return opts;
        }
        _update_disabled_options() {
            for (const element of this.input_el.options) {
                if (this.model.disabled_options.includes(element.value)) {
                    element.setAttribute("disabled", "true");
                }
                else {
                    element.removeAttribute("disabled");
                }
            }
        }
    }
    exports.CustomSelectView = CustomSelectView;
    CustomSelectView.__name__ = "CustomSelectView";
    class CustomSelect extends select_1.Select {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.CustomSelect = CustomSelect;
    _a = CustomSelect;
    CustomSelect.__name__ = "CustomSelect";
    CustomSelect.__module__ = "panel.models.widgets";
    (() => {
        _a.prototype.default_view = CustomSelectView;
        _a.define(({ List, Str }) => {
            return {
                disabled_options: [List(Str), []],
            };
        });
    })();
}
