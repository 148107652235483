/* models/progress.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const dom_1 = require("@bokehjs/core/dom");
    const layout_1 = require("73d6aee8f5") /* ./layout */;
    class ProgressView extends layout_1.HTMLBoxView {
        connect_signals() {
            super.connect_signals();
            const { width, height, height_policy, width_policy, sizing_mode, active, bar_color, css_classes, value, max, } = this.model.properties;
            this.on_change([width, height, height_policy, width_policy, sizing_mode], () => this.render());
            this.on_change([active, bar_color, css_classes], () => this.setCSS());
            this.on_change(value, () => this.setValue());
            this.on_change(max, () => this.setMax());
        }
        render() {
            super.render();
            const style = { ...this.model.styles, display: "inline-block" };
            this.progressEl = document.createElement("progress");
            this.setValue();
            this.setMax();
            // Set styling
            this.setCSS();
            for (const prop in style) {
                this.progressEl.style.setProperty(prop, style[prop]);
            }
            this.shadow_el.appendChild(this.progressEl);
        }
        stylesheets() {
            const styles = super.stylesheets();
            for (const css of this.model.css) {
                styles.push(new dom_1.ImportedStyleSheet(css));
            }
            return styles;
        }
        setCSS() {
            let css = `${this.model.css_classes.join(" ")} ${this.model.bar_color}`;
            if (this.model.active) {
                css = `${css} active`;
            }
            this.progressEl.className = css;
        }
        setValue() {
            if (this.model.value == null) {
                this.progressEl.value = 0;
            }
            else if (this.model.value >= 0) {
                this.progressEl.value = this.model.value;
            }
            else if (this.model.value < 0) {
                this.progressEl.removeAttribute("value");
            }
        }
        setMax() {
            if (this.model.max != null) {
                this.progressEl.max = this.model.max;
            }
        }
    }
    exports.ProgressView = ProgressView;
    ProgressView.__name__ = "ProgressView";
    class Progress extends layout_1.HTMLBox {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.Progress = Progress;
    _a = Progress;
    Progress.__name__ = "Progress";
    Progress.__module__ = "panel.models.widgets";
    (() => {
        _a.prototype.default_view = ProgressView;
        _a.define(({ Any, List, Bool, Float, Str }) => ({
            active: [Bool, true],
            bar_color: [Str, "primary"],
            css: [List(Str), []],
            max: [Float, 100],
            value: [Any, null],
        }));
    })();
}
