/* models/button.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const tooltip_1 = require("@bokehjs/models/ui/tooltip");
    const build_views_1 = require("@bokehjs/core/build_views");
    const button_1 = require("@bokehjs/models/widgets/button");
    class ButtonView extends button_1.ButtonView {
        *children() {
            yield* super.children();
            if (this.tooltip != null) {
                yield this.tooltip;
            }
        }
        connect_signals() {
            super.connect_signals();
            const { tooltip } = this.model.properties;
            this.on_change(tooltip, () => this.update_tooltip());
        }
        async update_tooltip() {
            if (this.tooltip != null) {
                this.tooltip.remove();
            }
            const { tooltip } = this.model;
            if (tooltip != null) {
                this.tooltip = await (0, build_views_1.build_view)(tooltip, { parent: this });
            }
        }
        async lazy_initialize() {
            await super.lazy_initialize();
            const { tooltip } = this.model;
            if (tooltip != null) {
                this.tooltip = await (0, build_views_1.build_view)(tooltip, { parent: this });
            }
        }
        remove() {
            this.tooltip?.remove();
            super.remove();
        }
        render() {
            super.render();
            const toggle = (visible) => {
                this.tooltip?.model.setv({
                    visible,
                });
            };
            let timer;
            this.el.addEventListener("mouseenter", () => {
                timer = setTimeout(() => toggle(true), this.model.tooltip_delay);
            });
            this.el.addEventListener("mouseleave", () => {
                clearTimeout(timer);
                toggle(false);
            });
        }
    }
    exports.ButtonView = ButtonView;
    ButtonView.__name__ = "ButtonView";
    class Button extends button_1.Button {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.Button = Button;
    _a = Button;
    Button.__name__ = "Button";
    Button.__module__ = "panel.models.widgets";
    (() => {
        _a.prototype.default_view = ButtonView;
        _a.define(({ Nullable, Ref, Float }) => ({
            tooltip: [Nullable(Ref(tooltip_1.Tooltip)), null],
            tooltip_delay: [Float, 500],
        }));
    })();
}
