/* models/browser.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const view_1 = require("@bokehjs/core/view");
    const model_1 = require("@bokehjs/model");
    class BrowserInfoView extends view_1.View {
        initialize() {
            super.initialize();
            if (window.matchMedia != null) {
                this.model.dark_mode = window.matchMedia("(prefers-color-scheme: dark)").matches;
            }
            this.model.device_pixel_ratio = window.devicePixelRatio;
            if (navigator != null) {
                this.model.language = navigator.language;
                this.model.webdriver = navigator.webdriver;
            }
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if (timezone != null) {
                this.model.timezone = timezone;
            }
            const timezone_offset = new Date().getTimezoneOffset();
            if (timezone_offset != null) {
                this.model.timezone_offset = timezone_offset;
            }
            this._has_finished = true;
            this.notify_finished();
        }
    }
    exports.BrowserInfoView = BrowserInfoView;
    BrowserInfoView.__name__ = "BrowserInfoView";
    class BrowserInfo extends model_1.Model {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.BrowserInfo = BrowserInfo;
    _a = BrowserInfo;
    BrowserInfo.__name__ = "BrowserInfo";
    BrowserInfo.__module__ = "panel.models.browser";
    (() => {
        _a.prototype.default_view = BrowserInfoView;
        _a.define(({ Bool, Nullable, Float, Str }) => ({
            dark_mode: [Nullable(Bool), null],
            device_pixel_ratio: [Nullable(Float), null],
            language: [Nullable(Str), null],
            timezone: [Nullable(Str), null],
            timezone_offset: [Nullable(Float), null],
            webdriver: [Nullable(Bool), null],
        }));
    })();
}
