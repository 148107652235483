/* models/chatarea_input.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a, _b;
    __esModule();
    const textarea_input_1 = require("b7d595d74a") /* ./textarea_input */;
    const bokeh_events_1 = require("@bokehjs/core/bokeh_events");
    class ChatMessageEvent extends bokeh_events_1.ModelEvent {
        constructor(value) {
            super();
            this.value = value;
        }
        get event_values() {
            return { model: this.origin, value: this.value };
        }
    }
    exports.ChatMessageEvent = ChatMessageEvent;
    _a = ChatMessageEvent;
    ChatMessageEvent.__name__ = "ChatMessageEvent";
    (() => {
        _a.prototype.event_name = "chat_message_event";
    })();
    class ChatAreaInputView extends textarea_input_1.TextAreaInputView {
        connect_signals() {
            super.connect_signals();
            const { value_input } = this.model.properties;
            this.on_change(value_input, () => this.update_rows());
        }
        render() {
            super.render();
            this.el.addEventListener("keydown", (event) => {
                if (event.key === "Enter") {
                    if (!event.shiftKey && (event.ctrlKey != this.model.enter_sends)) {
                        if (!this.model.disabled_enter) {
                            this.model.trigger_event(new ChatMessageEvent(this.model.value_input));
                            this.model.value_input = "";
                        }
                        event.preventDefault();
                    }
                    else if (event.ctrlKey && this.model.enter_sends) {
                        this.model.value_input += "\n";
                        event.preventDefault();
                    }
                }
            });
        }
    }
    exports.ChatAreaInputView = ChatAreaInputView;
    ChatAreaInputView.__name__ = "ChatAreaInputView";
    class ChatAreaInput extends textarea_input_1.TextAreaInput {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.ChatAreaInput = ChatAreaInput;
    _b = ChatAreaInput;
    ChatAreaInput.__name__ = "ChatAreaInput";
    ChatAreaInput.__module__ = "panel.models.chatarea_input";
    (() => {
        _b.prototype.default_view = ChatAreaInputView;
        _b.define(({ Bool }) => {
            return {
                disabled_enter: [Bool, false],
                enter_sends: [Bool, true],
            };
        });
    })();
}
