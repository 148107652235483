/* models/jsoneditor.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a, _b;
    __esModule();
    const dom_1 = require("@bokehjs/core/dom");
    const bokeh_events_1 = require("@bokehjs/core/bokeh_events");
    const layout_1 = require("73d6aee8f5") /* ./layout */;
    class JSONEditEvent extends bokeh_events_1.ModelEvent {
        constructor(data) {
            super();
            this.data = data;
        }
        get event_values() {
            return { model: this.origin, data: this.data };
        }
    }
    exports.JSONEditEvent = JSONEditEvent;
    _a = JSONEditEvent;
    JSONEditEvent.__name__ = "JSONEditEvent";
    (() => {
        _a.prototype.event_name = "json_edit";
    })();
    class JSONEditorView extends layout_1.HTMLBoxView {
        connect_signals() {
            super.connect_signals();
            const { data, disabled, templates, menu, mode, search, schema } = this.model.properties;
            this.on_change([data], () => this.editor.update(this.model.data));
            this.on_change([templates], () => {
                this.editor.options.templates = this.model.templates;
            });
            this.on_change([menu], () => {
                this.editor.options.menu = this.model.menu;
            });
            this.on_change([search], () => {
                this.editor.options.search = this.model.search;
            });
            this.on_change([schema], () => {
                this.editor.options.schema = this.model.schema;
            });
            this.on_change([disabled, mode], () => {
                const mode = this.model.disabled ? "view" : this.model.mode;
                this.editor.setMode(mode);
            });
        }
        stylesheets() {
            const styles = super.stylesheets();
            for (const css of this.model.css) {
                styles.push(new dom_1.ImportedStyleSheet(css));
            }
            return styles;
        }
        remove() {
            super.remove();
            this.editor.destroy();
        }
        render() {
            super.render();
            const mode = this.model.disabled ? "view" : this.model.mode;
            this.editor = new window.JSONEditor(this.shadow_el, {
                menu: this.model.menu,
                mode,
                onChangeJSON: (json) => {
                    this.model.data = json;
                },
                onSelectionChange: (start, end) => {
                    this.model.selection = [start, end];
                },
                search: this.model.search,
                schema: this.model.schema,
                templates: this.model.templates,
            });
            this.editor.set(this.model.data);
        }
    }
    exports.JSONEditorView = JSONEditorView;
    JSONEditorView.__name__ = "JSONEditorView";
    class JSONEditor extends layout_1.HTMLBox {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.JSONEditor = JSONEditor;
    _b = JSONEditor;
    JSONEditor.__name__ = "JSONEditor";
    JSONEditor.__module__ = "panel.models.jsoneditor";
    (() => {
        _b.prototype.default_view = JSONEditorView;
        _b.define(({ Any, List, Bool, Str }) => ({
            css: [List(Str), []],
            data: [Any, {}],
            mode: [Str, "tree"],
            menu: [Bool, true],
            search: [Bool, true],
            selection: [List(Any), []],
            schema: [Any, null],
            templates: [List(Any), []],
        }));
    })();
}
