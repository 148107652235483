/* models/mathjax.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const markup_1 = require("@bokehjs/models/widgets/markup");
    const layout_1 = require("73d6aee8f5") /* ./layout */;
    class MathJaxView extends layout_1.PanelMarkupView {
        connect_signals() {
            super.connect_signals();
            const { text } = this.model.properties;
            this.on_change(text, () => this.render());
        }
        render() {
            super.render();
            this.container.innerHTML = this.has_math_disabled() ? this.model.text : this.process_tex(this.model.text);
        }
    }
    exports.MathJaxView = MathJaxView;
    MathJaxView.__name__ = "MathJaxView";
    class MathJax extends markup_1.Markup {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.MathJax = MathJax;
    _a = MathJax;
    MathJax.__name__ = "MathJax";
    MathJax.__module__ = "panel.models.mathjax";
    (() => {
        _a.prototype.default_view = MathJaxView;
    })();
}
