/* models/tabulator.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a, _b, _c, _d;
    __esModule();
    const dom_1 = require("@bokehjs/core/dom");
    const types_1 = require("@bokehjs/core/util/types");
    const bokeh_events_1 = require("@bokehjs/core/bokeh_events");
    const dom_2 = require("@bokehjs/core/dom");
    const kinds_1 = require("@bokehjs/core/kinds");
    const column_data_source_1 = require("@bokehjs/models/sources/column_data_source");
    const tables_1 = require("@bokehjs/models/widgets/tables");
    const debounce_1 = require("99a25e6992") /* debounce */;
    const comm_manager_1 = require("d0a63056c9") /* ./comm_manager */;
    const data_1 = require("be689f0377") /* ./data */;
    const layout_1 = require("73d6aee8f5") /* ./layout */;
    class TableEditEvent extends bokeh_events_1.ModelEvent {
        constructor(column, row, pre) {
            super();
            this.column = column;
            this.row = row;
            this.pre = pre;
        }
        get event_values() {
            return { model: this.origin, column: this.column, row: this.row, pre: this.pre };
        }
    }
    exports.TableEditEvent = TableEditEvent;
    _a = TableEditEvent;
    TableEditEvent.__name__ = "TableEditEvent";
    (() => {
        _a.prototype.event_name = "table-edit";
    })();
    class CellClickEvent extends bokeh_events_1.ModelEvent {
        constructor(column, row) {
            super();
            this.column = column;
            this.row = row;
        }
        get event_values() {
            return { model: this.origin, column: this.column, row: this.row };
        }
    }
    exports.CellClickEvent = CellClickEvent;
    _b = CellClickEvent;
    CellClickEvent.__name__ = "CellClickEvent";
    (() => {
        _b.prototype.event_name = "cell-click";
    })();
    class SelectionEvent extends bokeh_events_1.ModelEvent {
        constructor(indices, selected, flush = false) {
            super();
            this.indices = indices;
            this.selected = selected;
            this.flush = flush;
        }
        get event_values() {
            return { model: this.origin, indices: this.indices, selected: this.selected, flush: this.flush };
        }
    }
    exports.SelectionEvent = SelectionEvent;
    _c = SelectionEvent;
    SelectionEvent.__name__ = "SelectionEvent";
    (() => {
        _c.prototype.event_name = "selection-change";
    })();
    function find_group(key, value, records) {
        for (const record of records) {
            if (record[key] == value) {
                return record;
            }
        }
        return null;
    }
    function summarize(grouped, columns, aggregators, depth = 0) {
        const summary = {};
        if (grouped.length == 0) {
            return summary;
        }
        const agg = aggregators[depth];
        for (const group of grouped) {
            const subsummary = summarize(group._children, columns, aggregators, depth + 1);
            for (const col in subsummary) {
                if ((0, types_1.isArray)(subsummary[col])) {
                    group[col] = subsummary[col].reduce((a, b) => a + b, 0) / subsummary[col].length;
                }
                else {
                    group[col] = subsummary[col];
                }
            }
            for (const column of columns.slice(1)) {
                const val = group[column.field];
                if (column.field in summary) {
                    const old_val = summary[column.field];
                    if (agg === "min") {
                        summary[column.field] = Math.min(val, old_val);
                    }
                    else if (agg === "max") {
                        summary[column.field] = Math.max(val, old_val);
                    }
                    else if (agg === "sum") {
                        summary[column.field] = val + old_val;
                    }
                    else if (agg === "mean") {
                        if ((0, types_1.isArray)(summary[column.field])) {
                            summary[column.field].push(val);
                        }
                        else {
                            summary[column.field] = [old_val, val];
                        }
                    }
                }
                else {
                    summary[column.field] = val;
                }
            }
        }
        return summary;
    }
    function group_data(records, columns, indexes, aggregators) {
        const grouped = [];
        const index_field = columns[0].field;
        for (const record of records) {
            const value = record[indexes[0]];
            let group = find_group(index_field, value, grouped);
            if (group == null) {
                group = { _children: [] };
                group[index_field] = value;
                grouped.push(group);
            }
            let subgroup = group;
            const groups = {};
            for (const index of indexes.slice(1)) {
                subgroup = find_group(index_field, record[index], subgroup._children);
                if (subgroup == null) {
                    subgroup = { _children: [] };
                    subgroup[index_field] = record[index];
                    group._children.push(subgroup);
                }
                groups[index] = group;
                for (const column of columns.slice(1)) {
                    subgroup[column.field] = record[column];
                }
                group = subgroup;
            }
            for (const column of columns.slice(1)) {
                subgroup[column.field] = record[column.field];
            }
        }
        const aggs = [];
        for (const index of indexes) {
            aggs.push((index in aggregators) ? aggregators[index] : "sum");
        }
        summarize(grouped, columns, aggs);
        return grouped;
    }
    const timestampSorter = function (a, b, _aRow, _bRow, _column, _dir, _params) {
        // Bokeh serializes datetime objects as UNIX timestamps.
        //a, b - the two values being compared
        //aRow, bRow - the row components for the values being compared (useful if you need to access additional fields in the row data for the sort)
        //column - the column component for the column being sorted
        //dir - the direction of the sort ("asc" or "desc")
        //sorterParams - sorterParams object from column definition array
        // Added an _ in front of some parameters as they're unused and the Typescript compiler was complaining about it.
        // const alignEmptyValues = params.alignEmptyValues
        let emptyAlign;
        emptyAlign = 0;
        const opts = { zone: new window.luxon.IANAZone("UTC") };
        // NaN values are serialized to -9223372036854776 by Bokeh
        if (String(a) == "-9223372036854776") {
            a = window.luxon.DateTime.fromISO("invalid");
        }
        else {
            a = window.luxon.DateTime.fromMillis(a, opts);
        }
        if (String(b) == "-9223372036854776") {
            b = window.luxon.DateTime.fromISO("invalid");
        }
        else {
            b = window.luxon.DateTime.fromMillis(b, opts);
        }
        if (!a.isValid) {
            emptyAlign = !b.isValid ? 0 : -1;
        }
        else if (!b.isValid) {
            emptyAlign = 1;
        }
        else {
            //compare valid values
            return a - b;
        }
        // Invalid (e.g. NaN) always at the bottom
        emptyAlign *= -1;
        return emptyAlign;
    };
    const dateEditor = function (cell, onRendered, success, cancel) {
        //cell - the cell component for the editable cell
        //onRendered - function to call when the editor has been rendered
        //success - function to call to pass the successfully updated value to Tabulator
        //cancel - function to call to abort the edit and return to a normal cell
        //create and style input
        const rawValue = cell.getValue();
        const opts = { zone: new window.luxon.IANAZone("UTC") };
        let cellValue;
        if (rawValue === "NaN" || rawValue === null) {
            cellValue = null;
        }
        else {
            cellValue = window.luxon.DateTime.fromMillis(rawValue, opts).toFormat("yyyy-MM-dd");
        }
        const input = document.createElement("input");
        input.setAttribute("type", "date");
        input.style.padding = "4px";
        input.style.width = "100%";
        input.style.boxSizing = "border-box";
        input.value = cellValue;
        onRendered(() => {
            input.focus();
            input.style.height = "100%";
        });
        function onChange() {
            const new_val = window.luxon.DateTime.fromFormat(input.value, "yyyy-MM-dd", opts).toMillis();
            if (new_val != cellValue) {
                success(new_val);
            }
            else {
                cancel();
            }
        }
        //submit new value on blur or change
        input.addEventListener("blur", onChange);
        //submit new value on enter
        input.addEventListener("keydown", (e) => {
            if (e.key == "Enter") {
                setTimeout(onChange, 100);
            }
            if (e.key == "Escape") {
                setTimeout(cancel, 100);
            }
        });
        return input;
    };
    const datetimeEditor = function (cell, onRendered, success, cancel) {
        //cell - the cell component for the editable cell
        //onRendered - function to call when the editor has been rendered
        //success - function to call to pass the successfully updated value to Tabulator
        //cancel - function to call to abort the edit and return to a normal cell
        //create and style input
        const rawValue = cell.getValue();
        const opts = { zone: new window.luxon.IANAZone("UTC") };
        let cellValue;
        if (rawValue === "NaN" || rawValue === null) {
            cellValue = null;
        }
        else {
            cellValue = window.luxon.DateTime.fromMillis(rawValue, opts).toFormat("yyyy-MM-dd'T'T");
        }
        const input = document.createElement("input");
        input.setAttribute("type", "datetime-local");
        input.style.padding = "4px";
        input.style.width = "100%";
        input.style.boxSizing = "border-box";
        input.value = cellValue;
        onRendered(() => {
            input.focus();
            input.style.height = "100%";
        });
        function onChange() {
            const new_val = window.luxon.DateTime.fromFormat(input.value, "yyyy-MM-dd'T'T", opts).toMillis();
            if (new_val != cellValue) {
                success(new_val);
            }
            else {
                cancel();
            }
        }
        //submit new value on blur or change
        input.addEventListener("blur", onChange);
        //submit new value on enter
        input.addEventListener("keydown", (e) => {
            if (e.key == "Enter") {
                setTimeout(onChange, 100);
            }
            if (e.key == "Escape") {
                setTimeout(cancel, 100);
            }
        });
        return input;
    };
    class DataTabulatorView extends layout_1.HTMLBoxView {
        constructor() {
            super(...arguments);
            this.columns = new Map();
            this.container = null;
            this._tabulator_cell_updating = false;
            this._updating_page = false;
            this._updating_sort = false;
            this._selection_updating = false;
            this._lastVerticalScrollbarTopPosition = 0;
            this._lastHorizontalScrollbarLeftPosition = 0;
            this._applied_styles = false;
            this._building = false;
            this._debounced_redraw = null;
            this._restore_scroll = false;
        }
        connect_signals() {
            super.connect_signals();
            this._debounced_redraw = (0, debounce_1.debounce)(() => this._resize_redraw(), 20, false);
            const { configuration, layout, columns, groupby, visible, download, children, expanded, cell_styles, hidden_columns, page_size, page, max_page, frozen_rows, sorters, theme_classes, } = this.model.properties;
            this.on_change([configuration, layout, groupby], (0, debounce_1.debounce)(() => {
                this.invalidate_render();
            }, 20, false));
            this.on_change(visible, () => {
                if (this.model.visible) {
                    this.tabulator.element.style.visibility = "visible";
                }
            });
            this.on_change(columns, () => {
                this.tabulator.setColumns(this.getColumns());
                this.setHidden();
            });
            this.on_change(download, () => {
                const ftype = this.model.filename.endsWith(".json") ? "json" : "csv";
                this.tabulator.download(ftype, this.model.filename);
            });
            this.on_change(children, () => this.renderChildren());
            this.on_change(expanded, () => {
                // The first cell is the cell of the frozen _index column.
                for (const row of this.tabulator.rowManager.getRows()) {
                    if (row.cells.length > 0) {
                        row.cells[0].layoutElement();
                    }
                }
                // Make sure the expand icon is changed when expanded is
                // changed from Python.
                for (const row of this.tabulator.rowManager.getRows()) {
                    if (row.cells.length > 0) {
                        const index = row.data._index;
                        const icon = this.model.expanded.indexOf(index) < 0 ? "►" : "▼";
                        row.cells[1].element.innerText = icon;
                    }
                }
            });
            this.on_change(cell_styles, () => {
                if (this._applied_styles) {
                    this.tabulator.redraw(true);
                }
                this.setStyles();
            });
            this.on_change(hidden_columns, () => {
                this.setHidden();
                this.tabulator.redraw(true);
            });
            this.on_change(page_size, () => this.setPageSize());
            this.on_change(page, () => {
                if (!this._updating_page) {
                    this.setPage();
                }
            });
            this.on_change(visible, () => this.setVisibility());
            this.on_change(max_page, () => this.setMaxPage());
            this.on_change(frozen_rows, () => this.setFrozen());
            this.on_change(sorters, () => this.setSorters());
            this.on_change(theme_classes, () => this.setCSSClasses(this.tabulator.element));
            this.on_change(this.model.source.properties.data, () => {
                if (this.tabulator === undefined) {
                    return;
                }
                this._selection_updating = true;
                this.setData();
                this._selection_updating = false;
                this.postUpdate();
            });
            this.connect(this.model.source.streaming, () => this.addData());
            this.connect(this.model.source.patching, () => {
                const inds = this.model.source.selected.indices;
                this.updateOrAddData();
                this.record_scroll();
                // Restore indices since updating data may have reset checkbox column
                this.model.source.selected.indices = inds;
            });
            this.connect(this.model.source.selected.change, () => this.setSelection());
            this.connect(this.model.source.selected.properties.indices.change, () => this.setSelection());
        }
        get groupBy() {
            const groupby = (data) => {
                const groups = [];
                for (const g of this.model.groupby) {
                    const group = `${g}: ${data[g]}`;
                    groups.push(group);
                }
                return groups.join(", ");
            };
            return (this.model.groupby.length > 0) ? groupby : false;
        }
        get sorters() {
            const sorters = [];
            if (this.model.sorters.length > 0) {
                sorters.push({ column: "_index", dir: "asc" });
            }
            for (const sort of this.model.sorters.reverse()) {
                if (sort.column === undefined) {
                    sort.column = sort.field;
                }
                sorters.push(sort);
            }
            return sorters;
        }
        invalidate_render() {
            this.tabulator.destroy();
            this.tabulator = null;
            this.render();
        }
        redraw(columns = true, rows = true) {
            if (this._building || this.tabulator != null) {
                return;
            }
            if (columns && (this.tabulator.columnManager.element != null)) {
                this.tabulator.columnManager.redraw(true);
            }
            if (rows && (this.tabulator.rowManager.renderer != null)) {
                this.tabulator.rowManager.redraw(true);
                this.renderChildren();
                this.setStyles();
            }
            this._restore_scroll = true;
        }
        after_layout() {
            super.after_layout();
            if (this.tabulator != null && this._initializing) {
                this.redraw();
            }
            this._initializing = false;
        }
        after_resize() {
            super.after_resize();
            this._debounced_redraw();
        }
        _resize_redraw() {
            if (this._initializing || !this.container || this._building) {
                return;
            }
            const width = this.container.clientWidth;
            const height = this.container.clientHeight;
            if (!width || !height) {
                return;
            }
            this.redraw(true, true);
            this.restore_scroll();
        }
        setCSSClasses(el) {
            el.className = "pnx-tabulator tabulator";
            for (const cls of this.model.theme_classes) {
                el.classList.add(cls);
            }
        }
        render() {
            if (this.tabulator != null) {
                this.tabulator.destroy();
            }
            super.render();
            this._initializing = true;
            const container = (0, dom_2.div)({ style: "display: contents;" });
            const el = (0, dom_2.div)({ style: "width: 100%; height: 100%; visibility: hidden;" });
            this.container = el;
            this.setCSSClasses(el);
            container.appendChild(el);
            this.shadow_el.appendChild(container);
            const configuration = this.getConfiguration();
            this.tabulator = new Tabulator(el, configuration);
            this.watch_stylesheets();
            this.init_callbacks();
        }
        style_redraw() {
            if (this.model.visible) {
                this.tabulator.element.style.visibility = "visible";
            }
            if (!this._initializing && !this._building) {
                this.redraw();
            }
        }
        tableInit() {
            this._building = true;
            // Patch the ajax request and page data parsing methods
            const ajax = this.tabulator.modules.ajax;
            ajax.sendRequest = (_url, params, _config) => {
                return this.requestPage(params.page, params.sort);
            };
            this.tabulator.modules.page._parseRemoteData = () => {
                return false;
            };
        }
        init_callbacks() {
            // Initialization
            this.tabulator.on("tableBuilding", () => this.tableInit());
            this.tabulator.on("tableBuilt", () => this.tableBuilt());
            // Rendering callbacks
            this.tabulator.on("selectableCheck", (row) => {
                const selectable = this.model.selectable_rows;
                return (selectable == null) || selectable.includes(row._row.data._index);
            });
            this.tabulator.on("tooltips", (cell) => {
                return `${cell.getColumn().getField()}: ${cell.getValue()}`;
            });
            this.tabulator.on("scrollVertical", (0, debounce_1.debounce)(() => {
                this.record_scroll();
                this.setStyles();
            }, 50, false));
            this.tabulator.on("scrollHorizontal", (0, debounce_1.debounce)(() => {
                this.record_scroll();
            }, 50, false));
            // Sync state with model
            this.tabulator.on("rowSelectionChanged", (data, rows, selected, deselected) => this.rowSelectionChanged(data, rows, selected, deselected));
            this.tabulator.on("rowClick", (e, row) => this.rowClicked(e, row));
            this.tabulator.on("cellEdited", (cell) => this.cellEdited(cell));
            this.tabulator.on("dataFiltering", (filters) => {
                this.record_scroll();
                this.model.filters = filters;
            });
            this.tabulator.on("dataFiltered", (_, rows) => {
                if (this._building) {
                    return;
                }
                // Ensure that after filtering empty scroll renders
                if (rows.length === 0) {
                    this.tabulator.rowManager.renderEmptyScroll();
                }
                // Ensure that after filtering the page is updated
                this.updatePage(this.tabulator.getPage());
            });
            this.tabulator.on("pageLoaded", (pageno) => {
                this.updatePage(pageno);
            });
            this.tabulator.on("renderComplete", () => {
                if (this._building) {
                    return;
                }
                this.postUpdate();
            });
            this.tabulator.on("dataSorting", (sorters) => {
                const sorts = [];
                for (const s of sorters) {
                    if (s.field !== "_index") {
                        sorts.push({ field: s.field, dir: s.dir });
                    }
                }
                if (this.model.pagination !== "remote") {
                    this._updating_sort = true;
                    this.model.sorters = sorts.reverse();
                    this._updating_sort = false;
                }
            });
        }
        tableBuilt() {
            this._building = false;
            this.setSelection();
            this.renderChildren();
            this.setStyles();
            if (this.model.pagination) {
                this.setMaxPage();
                this.tabulator.setPage(this.model.page);
            }
        }
        requestPage(page, sorters) {
            return new Promise((resolve, reject) => {
                try {
                    if (page != null && sorters != null) {
                        this._updating_sort = true;
                        const sorts = [];
                        for (const s of sorters) {
                            if (s.field !== "_index") {
                                sorts.push({ field: s.field, dir: s.dir });
                            }
                        }
                        this.model.sorters = sorts;
                        this._updating_sort = false;
                        this._updating_page = true;
                        try {
                            this.model.page = page || 1;
                        }
                        finally {
                            this._updating_page = false;
                        }
                    }
                    resolve([]);
                }
                catch (err) {
                    reject(err);
                }
            });
        }
        getLayout() {
            const layout = this.model.layout;
            switch (layout) {
                case "fit_data":
                    return "fitData";
                case "fit_data_fill":
                    return "fitDataFill";
                case "fit_data_stretch":
                    return "fitDataStretch";
                case "fit_data_table":
                    return "fitDataTable";
                case "fit_columns":
                    return "fitColumns";
            }
        }
        getConfiguration() {
            // Only use selectable mode if explicitly requested otherwise manually handle selections
            const selectable = this.model.select_mode === "toggle" ? true : NaN;
            const configuration = {
                ...this.model.configuration,
                index: "_index",
                nestedFieldSeparator: false,
                movableColumns: false,
                selectable,
                columns: this.getColumns(),
                initialSort: this.sorters,
                layout: this.getLayout(),
                pagination: this.model.pagination != null,
                paginationMode: this.model.pagination,
                paginationSize: this.model.page_size,
                paginationInitialPage: 1,
                groupBy: this.groupBy,
                rowFormatter: (row) => this._render_row(row),
                frozenRows: (row) => {
                    return (this.model.frozen_rows.length > 0) ? this.model.frozen_rows.includes(row._row.data._index) : false;
                },
            };
            if (this.model.pagination === "remote") {
                configuration.ajaxURL = "http://panel.pyviz.org";
                configuration.sortMode = "remote";
            }
            const cds = this.model.source;
            let data;
            if (cds === null || (cds.columns().length === 0)) {
                data = [];
            }
            else {
                data = (0, data_1.transform_cds_to_records)(cds, true);
            }
            if (configuration.dataTree) {
                data = group_data(data, this.model.columns, this.model.indexes, this.model.aggregators);
            }
            return {
                ...configuration,
                data,
            };
        }
        get child_models() {
            const children = [];
            for (const idx of this.model.expanded) {
                const child = this.model.children.get(idx);
                if (child != null) {
                    children.push(child);
                }
            }
            return children;
        }
        renderChildren() {
            new Promise(async (resolve) => {
                const new_children = await this.build_child_views();
                resolve(new_children);
            }).then((new_children) => {
                for (const r of this.model.expanded) {
                    const row = this.tabulator.getRow(r);
                    const index = row._row?.data._index;
                    if (this.model.children.get(index) == null) {
                        continue;
                    }
                    const model = this.model.children.get(index);
                    const view = model == null ? null : this._child_views.get(model);
                    if ((view != null) && new_children.includes(view)) {
                        this._render_row(row, false);
                    }
                }
                this._update_children();
                if (this.tabulator.rowManager.renderer != null) {
                    this.tabulator.rowManager.adjustTableSize();
                }
                this.invalidate_layout();
            });
        }
        _render_row(row, resize = true) {
            const index = row._row?.data._index;
            if (!this.model.expanded.includes(index) || this.model.children.get(index) == null) {
                return;
            }
            const model = this.model.children.get(index);
            const view = model == null ? null : this._child_views.get(model);
            if (view == null) {
                return;
            }
            const rowEl = row.getElement();
            const style = getComputedStyle(this.tabulator.element.children[1].children[0]);
            const bg = style.backgroundColor;
            const neg_margin = rowEl.style.paddingLeft ? `-${rowEl.style.paddingLeft}` : "0";
            const viewEl = (0, dom_2.div)({ style: `background-color: ${bg}; margin-left:${neg_margin}; max-width: 100%; overflow-x: hidden;` });
            viewEl.appendChild(view.el);
            rowEl.appendChild(viewEl);
            if (!view.has_finished()) {
                view.render();
                view.after_render();
            }
            if (resize) {
                this._update_children();
                this.tabulator.rowManager.adjustTableSize();
                this.invalidate_layout();
            }
        }
        _expand_render(cell) {
            const index = cell._cell.row.data._index;
            const icon = this.model.expanded.indexOf(index) < 0 ? "►" : "▼";
            return `<i>${icon}</i>`;
        }
        _update_expand(cell) {
            const index = cell._cell.row.data._index;
            const expanded = [...this.model.expanded];
            const exp_index = expanded.indexOf(index);
            if (exp_index < 0) {
                expanded.push(index);
            }
            else {
                const removed = expanded.splice(exp_index, 1)[0];
                const model = this.model.children.get(removed);
                if (model != null) {
                    const view = this._child_views.get(model);
                    if (view !== undefined && view.el != null) {
                        (0, dom_1.undisplay)(view.el);
                    }
                }
            }
            this.model.expanded = expanded;
            if (expanded.indexOf(index) < 0) {
                return;
            }
            let ready = true;
            for (const idx of this.model.expanded) {
                if (this.model.children.get(idx) == null) {
                    ready = false;
                    break;
                }
            }
            if (ready) {
                this.renderChildren();
            }
        }
        getData() {
            let data = (0, data_1.transform_cds_to_records)(this.model.source, true);
            if (this.model.configuration.dataTree) {
                data = group_data(data, this.model.columns, this.model.indexes, this.model.aggregators);
            }
            return data;
        }
        getColumns() {
            this.columns = new Map();
            const config_columns = this.model.configuration?.columns;
            const columns = [];
            columns.push({ field: "_index", frozen: true, visible: false });
            if (config_columns != null) {
                for (const column of config_columns) {
                    if (column.columns != null) {
                        const group_columns = [];
                        for (const col of column.columns) {
                            group_columns.push({ ...col });
                        }
                        columns.push({ ...column, columns: group_columns });
                    }
                    else if (column.formatter === "expand") {
                        const expand = {
                            hozAlign: "center",
                            cellClick: (_, cell) => {
                                this._update_expand(cell);
                            },
                            formatter: (cell) => {
                                return this._expand_render(cell);
                            },
                            width: 40,
                            frozen: true,
                        };
                        columns.push(expand);
                    }
                    else {
                        const new_column = { ...column };
                        if (new_column.formatter === "rowSelection") {
                            new_column.cellClick = (_, cell) => {
                                cell.getRow().toggleSelect();
                            };
                        }
                        columns.push(new_column);
                    }
                }
            }
            for (const column of this.model.columns) {
                let tab_column = null;
                if (config_columns != null) {
                    for (const col of columns) {
                        if (col.columns != null) {
                            for (const c of col.columns) {
                                if (column.field === c.field) {
                                    tab_column = c;
                                    break;
                                }
                            }
                            if (tab_column != null) {
                                break;
                            }
                        }
                        else if (column.field === col.field) {
                            tab_column = col;
                            break;
                        }
                    }
                }
                if (tab_column == null) {
                    tab_column = { field: column.field };
                }
                this.columns.set(column.field, tab_column);
                if (tab_column.title == null) {
                    tab_column.title = column.title;
                }
                if (tab_column.width == null && column.width != null && column.width != 0) {
                    tab_column.width = column.width;
                }
                if (tab_column.formatter == null && column.formatter != null) {
                    const formatter = column.formatter;
                    const ftype = formatter.type;
                    if (ftype === "BooleanFormatter") {
                        tab_column.formatter = "tickCross";
                    }
                    else {
                        tab_column.formatter = (cell) => {
                            const row = cell.getRow();
                            const formatted = column.formatter.doFormat(cell.getRow(), cell, cell.getValue(), null, row.getData());
                            if (column.formatter.type === "HTMLTemplateFormatter") {
                                return formatted;
                            }
                            const node = (0, dom_2.div)();
                            node.innerHTML = formatted;
                            const child = node.children[0];
                            if (child.innerHTML === "function(){return c.convert(arguments)}") { // If the formatter fails
                                return "";
                            }
                            return child;
                        };
                    }
                }
                if (tab_column.sorter == "timestamp") {
                    tab_column.sorter = timestampSorter;
                }
                if (tab_column.sorter === undefined) {
                    tab_column.sorter = "string";
                }
                const editor = column.editor;
                const ctype = editor.type;
                if (tab_column.editor != null) {
                    if (tab_column.editor === "date") {
                        tab_column.editor = dateEditor;
                    }
                    else if (tab_column.editor === "datetime") {
                        tab_column.editor = datetimeEditor;
                    }
                }
                else if (ctype === "StringEditor") {
                    if (editor.completions.length > 0) {
                        tab_column.editor = "list";
                        tab_column.editorParams = { values: editor.completions, autocomplete: true, listOnEmpty: true };
                    }
                    else {
                        tab_column.editor = "input";
                    }
                }
                else if (ctype === "TextEditor") {
                    tab_column.editor = "textarea";
                }
                else if (ctype === "IntEditor" || ctype === "NumberEditor") {
                    tab_column.editor = "number";
                    tab_column.editorParams = { step: editor.step };
                    if (ctype === "IntEditor") {
                        tab_column.validator = "integer";
                    }
                    else {
                        tab_column.validator = "numeric";
                    }
                }
                else if (ctype === "CheckboxEditor") {
                    tab_column.editor = "tickCross";
                }
                else if (ctype === "DateEditor") {
                    tab_column.editor = dateEditor;
                }
                else if (ctype === "SelectEditor") {
                    tab_column.editor = "list";
                    tab_column.editorParams = { values: editor.options };
                }
                else if (editor != null && editor.default_view != null) {
                    tab_column.editor = (cell, onRendered, success, cancel) => {
                        this.renderEditor(column, cell, onRendered, success, cancel);
                    };
                }
                tab_column.visible = (tab_column.visible != false && !this.model.hidden_columns.includes(column.field));
                tab_column.editable = () => (this.model.editable && (editor.default_view != null));
                if (tab_column.headerFilter) {
                    if ((0, types_1.isBoolean)(tab_column.headerFilter) && (0, types_1.isString)(tab_column.editor)) {
                        tab_column.headerFilter = tab_column.editor;
                        tab_column.headerFilterParams = tab_column.editorParams;
                    }
                }
                for (const sort of this.model.sorters) {
                    if (tab_column.field === sort.field) {
                        tab_column.headerSortStartingDir = sort.dir;
                    }
                }
                tab_column.cellClick = (_, cell) => {
                    const index = cell.getData()._index;
                    const event = new CellClickEvent(column.field, index);
                    this.model.trigger_event(event);
                };
                if (config_columns == null) {
                    columns.push(tab_column);
                }
            }
            for (const col in this.model.buttons) {
                const button_formatter = () => {
                    return this.model.buttons[col];
                };
                const button_column = {
                    formatter: button_formatter,
                    hozAlign: "center",
                    cellClick: (_, cell) => {
                        const index = cell.getData()._index;
                        const event = new CellClickEvent(col, index);
                        this.model.trigger_event(event);
                    },
                };
                columns.push(button_column);
            }
            return columns;
        }
        renderEditor(column, cell, onRendered, success, cancel) {
            const editor = column.editor;
            const view = new editor.default_view({ column, model: editor, parent: this, container: cell._cell.element });
            view.initialize();
            view.connect_signals();
            onRendered(() => {
                view.setValue(cell.getValue());
            });
            view.inputEl.addEventListener("input", () => {
                const value = view.serializeValue();
                const old_value = cell.getValue();
                const validation = view.validate();
                if (!validation.valid) {
                    cancel(validation.msg);
                }
                if (old_value != null && typeof value != typeof old_value) {
                    cancel("Mismatching type");
                }
                else {
                    success(view.serializeValue());
                }
            });
            return view.inputEl;
        }
        // Update table
        setData() {
            if (this._initializing || this._building || !this.tabulator.initialized) {
                return;
            }
            const data = this.getData();
            if (this.model.pagination != null) {
                this.tabulator.rowManager.setData(data, true, false);
            }
            else {
                this.tabulator.setData(data);
            }
        }
        addData() {
            const rows = this.tabulator.rowManager.getRows();
            const last_row = rows[rows.length - 1];
            const start = ((last_row?.data._index) || 0);
            this.setData();
            if (this.model.follow && last_row) {
                this.tabulator.scrollToRow(start, "top", false);
            }
        }
        postUpdate() {
            this.setSelection();
            this.setStyles();
            if (this._restore_scroll) {
                this.restore_scroll();
                this._restore_scroll = false;
            }
        }
        updateOrAddData() {
            // To avoid double updating the tabulator data
            if (this._tabulator_cell_updating) {
                return;
            }
            // Temporarily set minHeight to avoid "scroll-to-top" issues caused
            // by Tabulator JS entirely destroying the table when .setData is called.
            // Inspired by https://github.com/olifolkerd/tabulator/issues/4155
            const prev_minheight = this.tabulator.element.style.minHeight;
            this.tabulator.element.style.minHeight = `${this.tabulator.element.offsetHeight}px`;
            const data = (0, data_1.transform_cds_to_records)(this.model.source, true);
            this.tabulator.setData(data).then(() => {
                this.tabulator.element.style.minHeight = prev_minheight;
            });
        }
        setFrozen() {
            for (const row of this.model.frozen_rows) {
                this.tabulator.getRow(row).freeze();
            }
        }
        setVisibility() {
            if (this.tabulator == null) {
                return;
            }
            this.tabulator.element.style.visibility = this.model.visible ? "visible" : "hidden";
        }
        updatePage(pageno) {
            if (this.model.pagination === "local" && this.model.page !== pageno) {
                this._updating_page = true;
                this.model.page = pageno;
                this._updating_page = false;
                this.setStyles();
            }
        }
        setGroupBy() {
            this.tabulator.setGroupBy(this.groupBy);
        }
        setSorters() {
            if (this._updating_sort) {
                return;
            }
            this.tabulator.setSort(this.sorters);
        }
        setStyles() {
            const style_data = this.model.cell_styles.data;
            if (this.tabulator == null || this.tabulator.getDataCount() == 0 || style_data == null || !style_data.size) {
                return;
            }
            this._applied_styles = false;
            for (const r of style_data.keys()) {
                const row_style = style_data.get(r);
                const row = this.tabulator.getRow(r);
                if (!row) {
                    continue;
                }
                const cells = row._row.cells;
                for (const c of row_style.keys()) {
                    const style = row_style.get(c);
                    const cell = cells[c];
                    if (cell == null || !style.length) {
                        continue;
                    }
                    const element = cell.element;
                    for (const s of style) {
                        let prop, value;
                        if ((0, types_1.isArray)(s)) {
                            [prop, value] = s;
                        }
                        else if (!s.includes(":")) {
                            continue;
                        }
                        else {
                            [prop, value] = s.split(":");
                        }
                        element.style.setProperty(prop, value.trimLeft());
                        this._applied_styles = true;
                    }
                }
            }
        }
        setHidden() {
            for (const column of this.tabulator.getColumns()) {
                const col = column._column;
                if ((col.field == "_index") || this.model.hidden_columns.includes(col.field)) {
                    column.hide();
                }
                else {
                    column.show();
                }
            }
        }
        setMaxPage() {
            this.tabulator.setMaxPage(this.model.max_page);
            if (this.tabulator.modules.page.pagesElement) {
                this.tabulator.modules.page._setPageButtons();
            }
        }
        setPage() {
            this.tabulator.setPage(Math.min(this.model.max_page, this.model.page));
            if (this.model.pagination === "local") {
                this.renderChildren();
                this.setStyles();
            }
        }
        setPageSize() {
            this.tabulator.setPageSize(this.model.page_size);
            if (this.model.pagination === "local") {
                this.renderChildren();
                this.setStyles();
            }
        }
        setSelection() {
            if (this.tabulator == null || this._initializing || this._selection_updating || !this.tabulator.initialized) {
                return;
            }
            const indices = this.model.source.selected.indices;
            const current_indices = this.tabulator.getSelectedData().map((row) => row._index);
            if (JSON.stringify(indices) == JSON.stringify(current_indices)) {
                return;
            }
            this._selection_updating = true;
            this.tabulator.deselectRow();
            this.tabulator.selectRow(indices);
            for (const index of indices) {
                const row = this.tabulator.rowManager.findRow(index);
                if (row) {
                    this.tabulator.scrollToRow(index, "center", false).catch(() => { });
                }
            }
            this._selection_updating = false;
        }
        restore_scroll() {
            const opts = {
                top: this._lastVerticalScrollbarTopPosition,
                left: this._lastHorizontalScrollbarLeftPosition,
                behavior: "instant",
            };
            setTimeout(() => this.tabulator.rowManager.element.scrollTo(opts), 0);
        }
        // Update model
        record_scroll() {
            this._lastVerticalScrollbarTopPosition = this.tabulator.rowManager.element.scrollTop;
            this._lastHorizontalScrollbarLeftPosition = this.tabulator.rowManager.element.scrollLeft;
        }
        rowClicked(e, row) {
            if (this._selection_updating ||
                this._initializing ||
                (0, types_1.isString)(this.model.select_mode) ||
                this.model.select_mode === false || // selection disabled
                this.model.configuration.dataTree || // dataTree does not support selection
                e.srcElement?.innerText === "►" // expand button
            ) {
                return;
            }
            let indices = [];
            const selected = this.model.source.selected;
            const index = row._row.data._index;
            if (this.model.pagination === "remote") {
                const includes = this.model.source.selected.indices.indexOf(index) == -1;
                const flush = !(e.ctrlKey || e.metaKey || e.shiftKey);
                if (e.shiftKey && selected.indices.length) {
                    const start = selected.indices[selected.indices.length - 1];
                    if (index > start) {
                        for (let i = start; i <= index; i++) {
                            indices.push(i);
                        }
                    }
                    else {
                        for (let i = start; i >= index; i--) {
                            indices.push(i);
                        }
                    }
                }
                else {
                    indices.push(index);
                }
                this._selection_updating = true;
                this.model.trigger_event(new SelectionEvent(indices, includes, flush));
                this._selection_updating = false;
                return;
            }
            if (e.ctrlKey || e.metaKey) {
                indices = [...this.model.source.selected.indices];
            }
            else if (e.shiftKey && selected.indices.length) {
                const start = selected.indices[selected.indices.length - 1];
                if (index > start) {
                    for (let i = start; i < index; i++) {
                        indices.push(i);
                    }
                }
                else {
                    for (let i = start; i > index; i--) {
                        indices.push(i);
                    }
                }
            }
            if (indices.indexOf(index) < 0) {
                indices.push(index);
            }
            else {
                indices.splice(indices.indexOf(index), 1);
            }
            // Remove the first selected indices when selectable is an int.
            if ((0, types_1.isNumber)(this.model.select_mode)) {
                while (indices.length > this.model.select_mode) {
                    indices.shift();
                }
            }
            const filtered = this._filter_selected(indices);
            this.tabulator.deselectRow();
            this.tabulator.selectRow(filtered);
            this._selection_updating = true;
            selected.indices = filtered;
            this._selection_updating = false;
        }
        _filter_selected(indices) {
            const filtered = [];
            for (const ind of indices) {
                if (this.model.selectable_rows == null ||
                    this.model.selectable_rows.indexOf(ind) >= 0) {
                    filtered.push(ind);
                }
            }
            return filtered;
        }
        rowSelectionChanged(data, _row, selected, deselected) {
            if (this._selection_updating ||
                this._initializing ||
                (0, types_1.isBoolean)(this.model.select_mode) ||
                (0, types_1.isNumber)(this.model.select_mode) ||
                this.model.configuration.dataTree) {
                return;
            }
            if (this.model.pagination === "remote") {
                const selected_indices = selected.map((x) => x._row.data._index);
                const deselected_indices = deselected.map((x) => x._row.data._index);
                if (selected_indices.length > 0) {
                    this._selection_updating = true;
                    this.model.trigger_event(new SelectionEvent(selected_indices, true, false));
                }
                if (deselected_indices.length > 0) {
                    this._selection_updating = true;
                    this.model.trigger_event(new SelectionEvent(deselected_indices, false, false));
                }
            }
            else {
                const indices = data.map((row) => row._index);
                const filtered = this._filter_selected(indices);
                this._selection_updating = indices.length === filtered.length;
                this.model.source.selected.indices = filtered;
            }
            this._selection_updating = false;
        }
        cellEdited(cell) {
            const field = cell._cell.column.field;
            const column_def = this.columns.get(field);
            const index = cell.getData()._index;
            const value = cell._cell.value;
            if (column_def.validator === "numeric" && value === "") {
                cell.setValue(NaN, true);
                return;
            }
            this._tabulator_cell_updating = true;
            comm_manager_1.comm_settings.debounce = false;
            this.model.trigger_event(new TableEditEvent(field, index, true));
            try {
                this.model.source.patch({ [field]: [[index, value]] });
            }
            finally {
                comm_manager_1.comm_settings.debounce = true;
                this._tabulator_cell_updating = false;
            }
            this.model.trigger_event(new TableEditEvent(field, index, false));
            this.tabulator.scrollToRow(index, "top", false);
        }
    }
    exports.DataTabulatorView = DataTabulatorView;
    DataTabulatorView.__name__ = "DataTabulatorView";
    exports.TableLayout = (0, kinds_1.Enum)("fit_data", "fit_data_fill", "fit_data_stretch", "fit_data_table", "fit_columns");
    // The Bokeh .ts model corresponding to the Bokeh .py model
    class DataTabulator extends layout_1.HTMLBox {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.DataTabulator = DataTabulator;
    _d = DataTabulator;
    DataTabulator.__name__ = "DataTabulator";
    DataTabulator.__module__ = "panel.models.tabulator";
    (() => {
        _d.prototype.default_view = DataTabulatorView;
        _d.define(({ Any, List, Bool, Nullable, Float, Ref, Str }) => ({
            aggregators: [Any, {}],
            buttons: [Any, {}],
            children: [Any, new Map()],
            configuration: [Any, {}],
            columns: [List(Ref(tables_1.TableColumn)), []],
            download: [Bool, false],
            editable: [Bool, true],
            expanded: [List(Float), []],
            filename: [Str, "table.csv"],
            filters: [List(Any), []],
            follow: [Bool, true],
            frozen_rows: [List(Float), []],
            groupby: [List(Str), []],
            hidden_columns: [List(Str), []],
            indexes: [List(Str), []],
            layout: [exports.TableLayout, "fit_data"],
            max_page: [Float, 0],
            pagination: [Nullable(Str), null],
            page: [Float, 0],
            page_size: [Float, 0],
            select_mode: [Any, true],
            selectable_rows: [Nullable(List(Float)), null],
            source: [Ref(column_data_source_1.ColumnDataSource)],
            sorters: [List(Any), []],
            cell_styles: [Any, {}],
            theme_classes: [List(Str), []],
        }));
    })();
}
