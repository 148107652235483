/* models/feed.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const column_1 = require("54717540c5") /* ./column */;
    const build_views_1 = require("@bokehjs/core/build_views");
    class FeedView extends column_1.ColumnView {
        constructor() {
            super(...arguments);
            this._lock = null;
        }
        initialize() {
            super.initialize();
            this._sync = true;
            this._intersection_observer = new IntersectionObserver((entries) => {
                const visible = [...this.model.visible_children];
                const nodes = this.node_map;
                for (const entry of entries) {
                    const id = nodes.get(entry.target).id;
                    if (entry.isIntersecting) {
                        if (!visible.includes(id)) {
                            visible.push(id);
                        }
                    }
                    else if (visible.includes(id)) {
                        visible.splice(visible.indexOf(id), 1);
                    }
                }
                if (this._sync) {
                    this.model.visible_children = visible;
                }
                if (visible.length > 0) {
                    const refs = this.child_models.map((model) => model.id);
                    const indices = visible.map((ref) => refs.indexOf(ref));
                    this._last_visible = this.child_views[Math.min(...indices)];
                }
                else {
                    this._last_visible = null;
                }
            }, {
                root: this.el,
                threshold: 0.01,
            });
        }
        get node_map() {
            const nodes = new Map();
            for (const view of this.child_views) {
                nodes.set(view.el, view.model);
            }
            return nodes;
        }
        async update_children() {
            const last = this._last_visible;
            const scroll_top = this.el.scrollTop;
            const before_offset = last?.el.offsetTop || 0;
            this._sync = false;
            await super.update_children();
            this._sync = true;
            requestAnimationFrame(() => {
                const after_offset = last?.el.offsetTop || 0;
                const offset = (after_offset - before_offset);
                if (offset > 0) {
                    this.el.scrollTo({ top: scroll_top + offset, behavior: "instant" });
                }
            });
        }
        async build_child_views() {
            const { created, removed } = await (0, build_views_1.build_views)(this._child_views, this.child_models, { parent: this });
            const visible = this.model.visible_children;
            for (const view of removed) {
                if (visible.includes(view.model.id)) {
                    visible.splice(visible.indexOf(view.model.id), 1);
                }
                this._resize_observer.unobserve(view.el);
                this._intersection_observer.unobserve(view.el);
            }
            this.model.visible_children = [...visible];
            for (const view of created) {
                this._resize_observer.observe(view.el, { box: "border-box" });
                this._intersection_observer.observe(view.el);
            }
            return created;
        }
        trigger_auto_scroll() {
            const limit = this.model.auto_scroll_limit;
            const within_limit = this.distance_from_latest <= limit;
            if (limit == 0 || !within_limit) {
                return;
            }
            this.scroll_to_latest();
        }
    }
    exports.FeedView = FeedView;
    FeedView.__name__ = "FeedView";
    class Feed extends column_1.Column {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.Feed = Feed;
    _a = Feed;
    Feed.__name__ = "Feed";
    Feed.__module__ = "panel.models.feed";
    (() => {
        _a.prototype.default_view = FeedView;
        _a.define(({ List, Str }) => ({
            visible_children: [List(Str), []],
        }));
    })();
}
