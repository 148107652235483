/* models/tabs.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const tslib_1 = require("tslib");
    const tabs = tslib_1.__importStar(require("@bokehjs/styles/tabs.css"));
    const grid_1 = require("@bokehjs/core/layout/grid");
    const enums_1 = require("@bokehjs/core/enums");
    const alignments_1 = require("@bokehjs/models/layouts/alignments");
    const tabs_1 = require("@bokehjs/models/layouts/tabs");
    const layout_dom_1 = require("@bokehjs/models/layouts/layout_dom");
    function show(element) {
        element.style.visibility = "";
        element.style.opacity = "";
    }
    function hide(element) {
        element.style.visibility = "hidden";
        element.style.opacity = "0";
    }
    class TabsView extends tabs_1.TabsView {
        connect_signals() {
            super.connect_signals();
            let view = this;
            while (view != null) {
                if (view.model.type.endsWith("Tabs")) {
                    view.connect(view.model.properties.active.change, () => this.update_zindex());
                }
                view = view.parent || view._parent; // Handle ReactiveHTML
            }
        }
        get is_visible() {
            let parent = this.parent;
            let current_view = this;
            while (parent != null) {
                if (parent.model.type.endsWith("Tabs")) {
                    if (parent.child_views.indexOf(current_view) !== parent.model.active) {
                        return false;
                    }
                }
                current_view = parent;
                parent = parent.parent || parent._parent; // Handle ReactiveHTML
            }
            return true;
        }
        render() {
            super.render();
            this.update_zindex();
        }
        update_zindex() {
            const { child_views } = this;
            for (const child_view of child_views) {
                if (child_view != null && child_view.el != null) {
                    child_view.el.style.zIndex = "";
                }
            }
            if (this.is_visible) {
                const active = child_views[this.model.active];
                if (active != null && active.el != null) {
                    active.el.style.zIndex = "1";
                }
            }
        }
        _after_layout() {
            layout_dom_1.LayoutDOMView.prototype._after_layout.call(this);
            const { child_views } = this;
            for (const child_view of child_views) {
                if (child_view !== undefined) {
                    hide(child_view.el);
                }
            }
            const { active } = this.model;
            if (active in child_views) {
                const tab = child_views[active];
                if (tab !== undefined) {
                    show(tab.el);
                }
            }
        }
        _update_layout() {
            layout_dom_1.LayoutDOMView.prototype._update_layout.call(this);
            const loc = this.model.tabs_location;
            this.class_list.remove([...enums_1.Location].map((loc) => tabs[loc]));
            this.class_list.add(tabs[loc]);
            const layoutable = new grid_1.Container();
            for (const view of this.child_views) {
                if (view == undefined) {
                    continue;
                }
                view.style.append(":host", { grid_area: "stack" });
                if (view instanceof layout_dom_1.LayoutDOMView && view.layout != null) {
                    layoutable.add({ r0: 0, c0: 0, r1: 1, c1: 1 }, view);
                }
            }
            if (layoutable.size != 0) {
                this.layout = new alignments_1.GridAlignmentLayout(layoutable);
                this.layout.set_sizing();
            }
            else {
                delete this.layout;
            }
        }
        update_active() {
            const i = this.model.active;
            const { header_els } = this;
            for (const el of header_els) {
                el.classList.remove(tabs.active);
            }
            if (i in header_els) {
                header_els[i].classList.add(tabs.active);
            }
            const { child_views } = this;
            for (const child_view of child_views) {
                hide(child_view.el);
            }
            if (i in child_views) {
                const view = child_views[i];
                show(view.el);
                if (view.invalidate_render == null) {
                    view.invalidate_render();
                }
            }
        }
    }
    exports.TabsView = TabsView;
    TabsView.__name__ = "TabsView";
    class Tabs extends tabs_1.Tabs {
    }
    exports.Tabs = Tabs;
    _a = Tabs;
    Tabs.__name__ = "Tabs";
    Tabs.__module__ = "panel.models.tabs";
    (() => {
        _a.prototype.default_view = TabsView;
    })();
}
