/* models/speech_to_text.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const layout_1 = require("73d6aee8f5") /* ./layout */;
    const iconStarted = `<svg xmlns="http://www.w3.org/2000/svg" height="22px" style="vertical-align: middle;" fill="currentColor" class="bi bi-mic" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
  <path fill-rule="evenodd" d="M10 8V3a2 2 0 1 0-4 0v5a2 2 0 1 0 4 0zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"/>
</svg>`;
    const iconNotStarted = `<svg xmlns="http://www.w3.org/2000/svg" height="22px" style="vertical-align: middle;" fill="currentColor" class="bi bi-mic-mute" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M12.734 9.613A4.995 4.995 0 0 0 13 8V7a.5.5 0 0 0-1 0v1c0 .274-.027.54-.08.799l.814.814zm-2.522 1.72A4 4 0 0 1 4 8V7a.5.5 0 0 0-1 0v1a5 5 0 0 0 4.5 4.975V15h-3a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-3v-2.025a4.973 4.973 0 0 0 2.43-.923l-.718-.719zM11 7.88V3a3 3 0 0 0-5.842-.963l.845.845A2 2 0 0 1 10 3v3.879l1 1zM8.738 9.86l.748.748A3 3 0 0 1 5 8V6.121l1 1V8a2 2 0 0 0 2.738 1.86zm4.908 3.494l-12-12 .708-.708 12 12-.708.707z"/>
</svg>`;
    const titleStarted = "Click to STOP the speech recognition.";
    const titleNotStarted = "Click to START the speech recognition.";
    const { webkitSpeechRecognition } = window;
    const { webkitSpeechGrammarList } = window;
    function htmlToElement(html) {
        const template = document.createElement("template");
        html = html.trim(); // Never return a text node of whitespace as the result
        template.innerHTML = html;
        return template.content.firstChild;
    }
    function deserializeGrammars(grammars) {
        if (grammars) {
            const speechRecognitionList = new webkitSpeechGrammarList();
            for (const grammar of grammars) {
                if (grammar.src) {
                    speechRecognitionList.addFromString(grammar.src, grammar.weight);
                }
                else if (grammar.uri) {
                    speechRecognitionList.addFromURI(grammar.uri, grammar.weight);
                }
            }
            return speechRecognitionList;
        }
        else {
            return null;
        }
    }
    function round(value) {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }
    function serializeResults(results_) {
        const results = [];
        for (const result of results_) {
            const alternatives = [];
            const item = { is_final: result.isFinal, alternatives };
            for (let i = 0; i < result.length; i++) {
                const alternative = {
                    confidence: round(result[i].confidence),
                    transcript: result[i].transcript,
                };
                alternatives.push(alternative);
            }
            item.alternatives = alternatives;
            results.push(item);
        }
        return results;
    }
    class SpeechToTextView extends layout_1.HTMLBoxView {
        initialize() {
            super.initialize();
            this.recognition = new webkitSpeechRecognition();
            this.recognition.lang = this.model.lang;
            this.recognition.continuous = this.model.continuous;
            this.recognition.interimResults = this.model.interim_results;
            this.recognition.maxAlternatives = this.model.max_alternatives;
            this.recognition.serviceURI = this.model.service_uri;
            this.setGrammars();
            this.recognition.onresult = (event) => {
                this.model.results = serializeResults(event.results);
            };
            this.recognition.onerror = (event) => {
                console.log("SpeechToText Error");
                console.log(event);
            };
            this.recognition.onnomatch = (event) => {
                console.log("SpeechToText No Match");
                console.log(event);
            };
            this.recognition.onaudiostart = () => this.model.audio_started = true;
            this.recognition.onaudioend = () => this.model.audio_started = false;
            this.recognition.onsoundstart = () => this.model.sound_started = true;
            this.recognition.onsoundend = () => this.model.sound_started = false;
            this.recognition.onspeechstart = () => this.model.speech_started = true;
            this.recognition.onspeechend = () => this.model.speech_started = false;
            this.recognition.onstart = () => {
                this.buttonEl.onclick = () => {
                    this.recognition.stop();
                };
                this.buttonEl.innerHTML = this.iconStarted();
                this.buttonEl.setAttribute("title", titleStarted);
                this.model.started = true;
            };
            this.recognition.onend = () => {
                this.buttonEl.onclick = () => {
                    this.recognition.start();
                };
                this.buttonEl.innerHTML = this.iconNotStarted();
                this.buttonEl.setAttribute("title", titleNotStarted);
                this.model.started = false;
            };
            this.buttonEl = htmlToElement(`<button class="bk bk-btn bk-btn-${this.model.button_type}" type="button" title="${titleNotStarted}"></button>`);
            this.buttonEl.innerHTML = this.iconNotStarted();
            this.buttonEl.onclick = () => this.recognition.start();
        }
        iconStarted() {
            if (this.model.button_started !== "") {
                return this.model.button_started;
            }
            else {
                return iconStarted;
            }
        }
        iconNotStarted() {
            if (this.model.button_not_started !== "") {
                return this.model.button_not_started;
            }
            else {
                return iconNotStarted;
            }
        }
        setIcon() {
            if (this.model.started) {
                this.buttonEl.innerHTML = this.iconStarted();
            }
            else {
                this.buttonEl.innerHTML = this.iconNotStarted();
            }
        }
        connect_signals() {
            super.connect_signals();
            const { start, stop, abort, grammars, lang, continuous, interim_results, max_alternatives, service_uri, button_type, button_hide, button_not_started, button_started, } = this.model.properties;
            this.on_change(start, () => {
                this.model.start = false;
                this.recognition.start();
            });
            this.on_change(stop, () => {
                this.model.stop = false;
                this.recognition.stop();
            });
            this.on_change(abort, () => {
                this.model.abort = false;
                this.recognition.abort();
            });
            this.on_change(grammars, () => this.setGrammars());
            this.on_change(lang, () => this.recognition.lang = this.model.lang);
            this.on_change(continuous, () => this.recognition.continuous = this.model.continuous);
            this.on_change(interim_results, () => this.recognition.interimResults = this.model.interim_results);
            this.on_change(max_alternatives, () => this.recognition.maxAlternatives = this.model.max_alternatives);
            this.on_change(service_uri, () => this.recognition.serviceURI = this.model.service_uri);
            this.on_change(button_type, () => this.buttonEl.className = `bk bk-btn bk-btn-${this.model.button_type}`);
            this.on_change(button_hide, () => this.render());
            this.on_change([button_not_started, button_started], () => this.setIcon());
        }
        setGrammars() {
            this.recognition.grammars = deserializeGrammars(this.model.grammars);
        }
        render() {
            super.render();
            if (!this.model.button_hide) {
                this.shadow_el.appendChild(this.buttonEl);
            }
        }
    }
    exports.SpeechToTextView = SpeechToTextView;
    SpeechToTextView.__name__ = "SpeechToTextView";
    class SpeechToText extends layout_1.HTMLBox {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.SpeechToText = SpeechToText;
    _a = SpeechToText;
    SpeechToText.__name__ = "SpeechToText";
    SpeechToText.__module__ = "panel.models.speech_to_text";
    (() => {
        _a.prototype.default_view = SpeechToTextView;
        _a.define(({ Any, List, Bool, Float, Str }) => ({
            start: [Bool, false],
            stop: [Bool, false],
            abort: [Bool, false],
            grammars: [List(Any), []],
            lang: [Str, ""],
            continuous: [Bool, false],
            interim_results: [Bool, false],
            max_alternatives: [Float, 1],
            service_uri: [Str, ""],
            started: [Bool, false],
            audio_started: [Bool, false],
            sound_started: [Bool, false],
            speech_started: [Bool, false],
            button_type: [Str, "light"],
            button_hide: [Bool, false],
            button_not_started: [Str, ""],
            button_started: [Str, ""],
            results: [List(Any), []],
        }));
    })();
}
