/* models/pdf.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const markup_1 = require("@bokehjs/models/widgets/markup");
    const layout_1 = require("73d6aee8f5") /* ./layout */;
    const html_1 = require("89d2d3667a") /* ./html */;
    class PDFView extends layout_1.PanelMarkupView {
        connect_signals() {
            super.connect_signals();
            const { text, width, height, embed, start_page } = this.model.properties;
            this.on_change([text, width, height, embed, start_page], () => { this.update(); });
        }
        render() {
            super.render();
            this.update();
        }
        update() {
            if (this.model.embed) {
                const blob = this.convert_base64_to_blob();
                const url = URL.createObjectURL(blob);
                this.container.innerHTML = `<embed src="${url}#page=${this.model.start_page}" type="application/pdf" width="100%" height="100%"></embed>`;
            }
            else {
                const html = (0, html_1.htmlDecode)(this.model.text);
                this.container.innerHTML = html || "";
            }
        }
        convert_base64_to_blob() {
            const byte_characters = atob(this.model.text);
            const slice_size = 512;
            const byte_arrays = [];
            for (let offset = 0; offset < byte_characters.length; offset += slice_size) {
                const slice = byte_characters.slice(offset, offset + slice_size);
                const byte_numbers = new Uint8Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byte_numbers[i] = slice.charCodeAt(i);
                }
                byte_arrays.push(byte_numbers);
            }
            return new Blob(byte_arrays, { type: "application/pdf" });
        }
    }
    exports.PDFView = PDFView;
    PDFView.__name__ = "PDFView";
    class PDF extends markup_1.Markup {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.PDF = PDF;
    _a = PDF;
    PDF.__name__ = "PDF";
    PDF.__module__ = "panel.models.markup";
    (() => {
        _a.prototype.default_view = PDFView;
        _a.define(({ Int, Bool }) => ({
            embed: [Bool, false],
            start_page: [Int, 1],
        }));
    })();
}
