/* models/multiselect.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a, _b;
    __esModule();
    const multiselect_1 = require("@bokehjs/models/widgets/multiselect");
    const bokeh_events_1 = require("@bokehjs/core/bokeh_events");
    class DoubleClickEvent extends bokeh_events_1.ModelEvent {
        constructor(option) {
            super();
            this.option = option;
        }
        get event_values() {
            return { model: this.origin, option: this.option };
        }
    }
    exports.DoubleClickEvent = DoubleClickEvent;
    _a = DoubleClickEvent;
    DoubleClickEvent.__name__ = "DoubleClickEvent";
    (() => {
        _a.prototype.event_name = "dblclick_event";
    })();
    class CustomMultiSelectView extends multiselect_1.MultiSelectView {
        render() {
            super.render();
            for (const option of this.input_el.children) {
                option.addEventListener("dblclick", (event) => {
                    if (event.target) {
                        this.model.trigger_event(new DoubleClickEvent(event.target.value));
                    }
                });
            }
        }
    }
    exports.CustomMultiSelectView = CustomMultiSelectView;
    CustomMultiSelectView.__name__ = "CustomMultiSelectView";
    class CustomMultiSelect extends multiselect_1.MultiSelect {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.CustomMultiSelect = CustomMultiSelect;
    _b = CustomMultiSelect;
    CustomMultiSelect.__name__ = "CustomMultiSelect";
    CustomMultiSelect.__module__ = "panel.models.widgets";
    (() => {
        _b.prototype.default_view = CustomMultiSelectView;
    })();
}
