/* models/icon.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const tooltip_1 = require("@bokehjs/models/ui/tooltip");
    const tabler_icon_1 = require("@bokehjs/models/ui/icons/tabler_icon");
    const svg_icon_1 = require("@bokehjs/models/ui/icons/svg_icon");
    const control_1 = require("@bokehjs/models/widgets/control");
    const dom_1 = require("@bokehjs/core/dom");
    const build_views_1 = require("@bokehjs/core/build_views");
    const bokeh_events_1 = require("@bokehjs/core/bokeh_events");
    class ClickableIconView extends control_1.ControlView {
        *controls() { }
        remove() {
            this.tooltip?.remove();
            this.icon_view.remove();
            super.remove();
        }
        async lazy_initialize() {
            await super.lazy_initialize();
            this.was_svg_icon = this.is_svg_icon(this.model.icon);
            this.label_el = (0, dom_1.div)({ class: "bk-IconLabel" }, this.model.title);
            this.label_el.style.fontSize = this.calculate_size(0.6);
            this.icon_view = await this.build_icon_model(this.model.icon, this.was_svg_icon);
            const { tooltip } = this.model;
            if (tooltip != null) {
                this.tooltip = await (0, build_views_1.build_view)(tooltip, { parent: this });
            }
        }
        *children() {
            yield* super.children();
            yield this.icon_view;
            if (this.tooltip != null) {
                yield this.tooltip;
            }
        }
        is_svg_icon(icon) {
            return icon.trim().startsWith("<svg");
        }
        connect_signals() {
            super.connect_signals();
            const { icon, active_icon, disabled, value, size, tooltip } = this.model.properties;
            this.on_change([active_icon, icon, value], () => this.update_icon());
            this.on_change(this.model.properties.title, () => this.update_label());
            this.on_change(disabled, () => this.update_cursor());
            this.on_change(size, () => this.update_size());
            this.on_change(tooltip, () => this.update_tooltip());
        }
        async update_tooltip() {
            if (this.tooltip != null) {
                this.tooltip.remove();
            }
            const { tooltip } = this.model;
            if (tooltip != null) {
                this.tooltip = await (0, build_views_1.build_view)(tooltip, { parent: this });
            }
        }
        render() {
            super.render();
            this.icon_view.render();
            this.update_icon();
            this.update_label();
            this.update_cursor();
            this.row_div = (0, dom_1.div)({
                class: "bk-IconRow",
            }, this.icon_view.el, this.label_el);
            this.shadow_el.appendChild(this.row_div);
            const toggle_tooltip = (visible) => {
                this.tooltip?.model.setv({
                    visible,
                });
            };
            let timer;
            this.el.addEventListener("mouseenter", () => {
                timer = setTimeout(() => toggle_tooltip(true), this.model.tooltip_delay);
            });
            this.el.addEventListener("mouseleave", () => {
                clearTimeout(timer);
                toggle_tooltip(false);
            });
        }
        update_label() {
            this.label_el.innerText = this.model.title;
        }
        update_cursor() {
            this.icon_view.el.style.cursor = this.model.disabled ? "not-allowed" : "pointer";
        }
        update_size() {
            this.icon_view.model.size = this.calculate_size();
            this.label_el.style.fontSize = this.calculate_size(0.6);
        }
        async build_icon_model(icon, is_svg_icon) {
            const size = this.calculate_size();
            const icon_model = (() => {
                if (is_svg_icon) {
                    return new svg_icon_1.SVGIcon({ svg: icon, size });
                }
                else {
                    return new tabler_icon_1.TablerIcon({ icon_name: icon, size });
                }
            })();
            const icon_view = await (0, build_views_1.build_view)(icon_model, { parent: this });
            icon_view.el.addEventListener("click", () => this.click());
            return icon_view;
        }
        async update_icon() {
            const icon = this.model.value ? this.get_active_icon() : this.model.icon;
            this.class_list.toggle("active", this.model.value);
            const is_svg_icon = this.is_svg_icon(icon);
            if (this.was_svg_icon !== is_svg_icon) {
                // If the icon type has changed, we need to rebuild the icon view
                // and invalidate the old one.
                const icon_view = await this.build_icon_model(icon, is_svg_icon);
                icon_view.render();
                this.icon_view.remove();
                this.icon_view = icon_view;
                this.was_svg_icon = is_svg_icon;
                this.update_cursor();
                // We need to re-append the new icon view to the DOM
                this.row_div.insertBefore(this.icon_view.el, this.label_el);
            }
            else if (is_svg_icon) {
                this.icon_view.model.svg = icon;
            }
            else {
                this.icon_view.model.icon_name = icon;
            }
            this.icon_view.el.style.lineHeight = "0";
        }
        get_active_icon() {
            return this.model.active_icon !== "" ? this.model.active_icon : `${this.model.icon}-filled`;
        }
        calculate_size(factor = 1) {
            if (this.model.size !== null) {
                return `calc(${this.model.size} * ${factor})`;
            }
            const maxWidth = this.model.width ?? 15;
            const maxHeight = this.model.height ?? 15;
            const size = Math.max(maxWidth, maxHeight) * factor;
            return `${size}px`;
        }
        click() {
            this.model.trigger_event(new bokeh_events_1.ButtonClick());
        }
    }
    exports.ClickableIconView = ClickableIconView;
    ClickableIconView.__name__ = "ClickableIconView";
    class ClickableIcon extends control_1.Control {
        constructor(attrs) {
            super(attrs);
        }
        on_click(callback) {
            this.on_event(bokeh_events_1.ButtonClick, callback);
        }
    }
    exports.ClickableIcon = ClickableIcon;
    _a = ClickableIcon;
    ClickableIcon.__name__ = "ClickableIcon";
    ClickableIcon.__module__ = "panel.models.icon";
    (() => {
        _a.prototype.default_view = ClickableIconView;
        _a.define(({ Nullable, Ref, Float, Str, Bool }) => ({
            active_icon: [Str, ""],
            icon: [Str, "heart"],
            size: [Nullable(Str), null],
            value: [Bool, false],
            title: [Str, ""],
            tooltip: [Nullable(Ref(tooltip_1.Tooltip)), null],
            tooltip_delay: [Float, 500],
        }));
    })();
}
