/* models/location.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const view_1 = require("@bokehjs/core/view");
    const model_1 = require("@bokehjs/model");
    class LocationView extends view_1.View {
        initialize() {
            super.initialize();
            this.model.pathname = window.location.pathname;
            this.model.search = window.location.search;
            this.model.hash = window.location.hash;
            // Readonly parameters on python side
            this.model.href = window.location.href;
            this.model.hostname = window.location.hostname;
            this.model.protocol = window.location.protocol;
            this.model.port = window.location.port;
            this._hash_listener = () => {
                this.model.hash = window.location.hash;
            };
            window.addEventListener("hashchange", this._hash_listener);
            this._has_finished = true;
            this.notify_finished();
        }
        connect_signals() {
            super.connect_signals();
            const { pathname, search, hash, reload } = this.model.properties;
            this.on_change(pathname, () => this.update("pathname"));
            this.on_change(search, () => this.update("search"));
            this.on_change(hash, () => this.update("hash"));
            this.on_change(reload, () => this.update("reload"));
        }
        remove() {
            super.remove();
            window.removeEventListener("hashchange", this._hash_listener);
        }
        update(change) {
            if (!this.model.reload || (change === "reload")) {
                window.history.pushState({}, "", `${this.model.pathname}${this.model.search}${this.model.hash}`);
                this.model.href = window.location.href;
                if (change === "reload") {
                    window.location.reload();
                }
            }
            else {
                if (change == "pathname") {
                    window.location.pathname = (this.model.pathname);
                }
                if (change == "search") {
                    window.location.search = (this.model.search);
                }
                if (change == "hash") {
                    window.location.hash = (this.model.hash);
                }
            }
        }
    }
    exports.LocationView = LocationView;
    LocationView.__name__ = "LocationView";
    class Location extends model_1.Model {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.Location = Location;
    _a = Location;
    Location.__name__ = "Location";
    Location.__module__ = "panel.models.location";
    (() => {
        _a.prototype.default_view = LocationView;
        _a.define(({ Bool, Str }) => ({
            href: [Str, ""],
            hostname: [Str, ""],
            pathname: [Str, ""],
            protocol: [Str, ""],
            port: [Str, ""],
            search: [Str, ""],
            hash: [Str, ""],
            reload: [Bool, false],
        }));
    })();
}
