/* models/vtk/vtkjs.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const vtklayout_1 = require("b06d05fa3e") /* ./vtklayout */;
    const util_1 = require("df9946ff52") /* ./util */;
    class VTKJSPlotView extends vtklayout_1.AbstractVTKView {
        connect_signals() {
            super.connect_signals();
            const { data } = this.model.properties;
            this.on_change(data, () => {
                this.invalidate_render();
            });
        }
        render() {
            super.render();
            this._create_orientation_widget();
            this._set_axes();
        }
        invalidate_render() {
            this._vtk_renwin = null;
            super.invalidate_render();
        }
        init_vtk_renwin() {
            this._vtk_renwin = util_1.vtkns.FullScreenRenderWindow.newInstance({
                rootContainer: this._vtk_container,
                container: this._vtk_container,
            });
        }
        plot() {
            if (this.model.data == null && this.model.data_url == null) {
                this._vtk_renwin.getRenderWindow().render();
                return;
            }
            let bytes_promise;
            if (this.model.data_url) {
                bytes_promise = util_1.vtkns.DataAccessHelper.get("http").fetchBinary(this.model.data_url);
            }
            else {
                bytes_promise = async () => { this.model.data; };
            }
            bytes_promise.then((zipContent) => {
                const dataAccessHelper = util_1.vtkns.DataAccessHelper.get("zip", {
                    zipContent,
                    callback: (_zip) => {
                        const sceneImporter = util_1.vtkns.HttpSceneLoader.newInstance({
                            renderer: this._vtk_renwin.getRenderer(),
                            dataAccessHelper,
                        });
                        const fn = window.vtk.macro.debounce(() => {
                            setTimeout(() => {
                                if (this._axes == null && this.model.axes) {
                                    this._set_axes();
                                }
                                this._set_camera_state();
                                this._get_camera_state();
                                this._vtk_renwin.getRenderWindow().render();
                            }, 100);
                        }, 100);
                        sceneImporter.setUrl("index.json");
                        sceneImporter.onReady(fn);
                    },
                });
            });
        }
    }
    exports.VTKJSPlotView = VTKJSPlotView;
    VTKJSPlotView.__name__ = "VTKJSPlotView";
    class VTKJSPlot extends vtklayout_1.AbstractVTKPlot {
    }
    exports.VTKJSPlot = VTKJSPlot;
    _a = VTKJSPlot;
    VTKJSPlot.__name__ = "VTKJSPlot";
    (() => {
        _a.prototype.default_view = VTKJSPlotView;
        _a.define(({ Boolean, Bytes, Nullable, String }) => ({
            data: [Nullable(Bytes), null],
            data_url: [Nullable(String), null],
            enable_keybindings: [Boolean, false],
        }));
    })();
}
