/* models/katex.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const markup_1 = require("@bokehjs/models/widgets/markup");
    const layout_1 = require("73d6aee8f5") /* ./layout */;
    class KaTeXView extends layout_1.PanelMarkupView {
        connect_signals() {
            super.connect_signals();
            const { text } = this.model.properties;
            this.on_change(text, () => this.render());
        }
        render() {
            super.render();
            this.container.innerHTML = this.model.text;
            if (!window.renderMathInElement) {
                return;
            }
            window.renderMathInElement(this.shadow_el, {
                delimiters: [
                    { left: "$$", right: "$$", display: true },
                    { left: "\\[", right: "\\]", display: true },
                    { left: "$", right: "$", display: false },
                    { left: "\\(", right: "\\)", display: false },
                ],
            });
        }
    }
    exports.KaTeXView = KaTeXView;
    KaTeXView.__name__ = "KaTeXView";
    class KaTeX extends markup_1.Markup {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.KaTeX = KaTeX;
    _a = KaTeX;
    KaTeX.__name__ = "KaTeX";
    KaTeX.__module__ = "panel.models.katex";
    (() => {
        _a.prototype.default_view = KaTeXView;
    })();
}
