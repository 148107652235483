/* models/toggle_icon.js */ function _(require, module, exports, __esModule, __esExport) {
    var _a;
    __esModule();
    const icon_1 = require("17193475a8") /* ./icon */;
    class ToggleIconView extends icon_1.ClickableIconView {
        *controls() { }
        click() {
            if (this.model.disabled) {
                return;
            }
            super.click();
            this.model.value = !this.model.value;
        }
    }
    exports.ToggleIconView = ToggleIconView;
    ToggleIconView.__name__ = "ToggleIconView";
    class ToggleIcon extends icon_1.ClickableIcon {
        constructor(attrs) {
            super(attrs);
        }
    }
    exports.ToggleIcon = ToggleIcon;
    _a = ToggleIcon;
    ToggleIcon.__name__ = "ToggleIcon";
    ToggleIcon.__module__ = "panel.models.icon";
    (() => {
        _a.prototype.default_view = ToggleIconView;
        _a.define(({}) => ({}));
    })();
}
